<template>
  <div>

  

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="8"
          lg="8"
          md="8"
        >
            <user-edit-tab-social
          :user-data="userData"
          :pdf-data="pdfData"
          
        />
        </b-col>
        <b-col
          cols="12"
          xl="6"
          lg="6"
          md="6"
        >
           <user-view-user-timeline-card :user-data="userData" />
        </b-col>
      </b-row>

  

      <invoice-list />
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
//import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Vue } from 'vue-property-decorator';
import UserEditTabSocial from "../users-edit/UserEditTabSocial.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    UserEditTabSocial,
    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,

    //InvoiceList,
  },
  setup() {
    const userData = ref(null)
    const pdfData = ref(null);

    const USER_APP_STORE_MODULE_NAME = 'candidate'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

  store
      .dispatch("candidate/fetchUser", { id: router.currentRoute.params.id })
      .then((response) => {
        (userData.value = response.data.data)
          
          // store
          //   .dispatch("candidate/fetchPdf", {
          //     url: response.data.data.resumeFileUrl,
          //   })
          //   .then((response) => {
          //     const blob = new Blob([response.data]);
          //     const objectUrl = URL.createObjectURL(blob);
          //     pdfData.value = objectUrl;
          //   })
          //   .catch((error) => {
          //     for (const [key, value] of Object.entries(error.response.data.errors)) {
          //     Vue.$toast({
          //       component: ToastificationContent,
          //       props: {
          //         title: "error",
          //         icon: "AlertTriangleIcon",
          //         variant: "danger",
          //         text:value,
          //       },
          //     });
          //     }
          //     if (error.response.status === 404) {
          //       pdfData.value = undefined;
          //     }
          //   });
      })
      .catch((error) => {
        for (const [key, value] of Object.entries(error.response.data.errors)) {
          Vue.$toast({
          component: ToastificationContent,
          props: {
            title: "error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text:value,
          },
        });
        }
        if (error.response.status === 404) {
          userData.value = undefined;
        }
      });

    return {
      userData,
       pdfData,
    }
  },
}
</script>

<style>

</style>
